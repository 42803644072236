import { computed, inject, Injectable, signal } from '@angular/core'
import { IAsset } from '../interfaces/asset.interface'
import { EventService } from './event.service'
import { LogService } from './log.service'
import { AssetService } from './asset.service'
import { take } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import { StorageService } from './storage.service'

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {

  private _logger = inject(LogService)
  private _eventService = inject(EventService)
  private _assetService = inject(AssetService)
  private _storageService = inject(StorageService)

  assets = signal<IAsset[]>([])
  hasAssets = computed(() => this.assets().length > 0)
  assetsAreFull = computed(() => this.assets().length >= 3)

  LOCAL_STORAGE_KEY = 'comparison-assets'

  constructor() {
    this._eventService.clearComparisonAsset$.subscribe(equipmentNumber => this.removeAsset(equipmentNumber))
    this._eventService.clearAllComparisonAssets$.subscribe(() => this.clearAssets())
    this.loadAssetsFromLocalStorage()
  }

  addAsset(asset: IAsset): void {
    if (this.assetsAreFull() || !asset) {
      return
    }
    this.assets.update(assets => [...assets, asset])
    this.updateLocalStorage()
  }

  removeAsset(equipmentNumber: string): void {
    if (!equipmentNumber) {
      return
    }
    this.assets.update(assets => assets.filter(asset => asset.equipment_number !== equipmentNumber))
    this.updateLocalStorage()
  }

  clearAssets(): void {
    this.assets.set([])
    this.clearLocalStorage()
  }

  checkAssetBeingCompared(equipmentNumber: IAsset | string): boolean {
    if (typeof equipmentNumber === 'object') {
      equipmentNumber = equipmentNumber.equipment_number
    }
    return this.assets().some(asset => asset.equipment_number === equipmentNumber)
  }

  updateLocalStorage(): void {
    if (this.hasAssets()) {
      const assetIds = this.assets().map(asset => asset.equipment_number)
      this._storageService.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(assetIds))
    } else {
      this.clearLocalStorage()
    }
  }

  loadAssetsFromLocalStorage(): void {
    const assetsIdsStr = this._storageService.getItem(this.LOCAL_STORAGE_KEY)
    if (assetsIdsStr) {
      const assetIds = JSON.parse(assetsIdsStr)
      this._logger.debug('Loading comparison assets from local storage: ', assetIds)
      this._assetService.getAssetsById(3, assetIds).pipe(
        take(1),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this._logger.error('Error loading comparison assets from local storage', response)
          return
        }
        const { data } = response
        this.assets.set(data)
      })
    }
  }

  clearLocalStorage(): void {
    this._storageService.removeItem(this.LOCAL_STORAGE_KEY)
  }
}
