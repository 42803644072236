import { IJsonLogic } from '../interfaces/json-logic.interface'

export const jsonLogic = {
  and: (...commands: any): IJsonLogic => ({ and: [...commands] }),
  or: (...commands: any): IJsonLogic => ({ or: [...commands] }),
  inArray: (...commands: any): IJsonLogic => ({ in: [...commands] }),
  variable: (key: string): IJsonLogic => ({ var: key }),
  equals: (...commands: any): IJsonLogic => ({ '==': [...commands] }),
  doesNotEqual: (...commands: any): IJsonLogic => ({ '!=': [...commands] }),
  lt: (...commands: any): IJsonLogic => ({ '<': [...commands] }),
  lte: (...commands: any): IJsonLogic => ({ '<=': [...commands] }),
  gt: (...commands: any): IJsonLogic => ({ '>': [...commands] }),
  gte: (...commands: any): IJsonLogic => ({ '>=': [...commands] }),
}
