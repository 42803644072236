import { Component, OnInit, computed, inject, input, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { FormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ComparisonService } from 'src/app/core/services/comparison.service'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-comparison-toggle',
  template: `
    <div
      class="ComparisonToggle"
      [class.with-bg]="theme() === 'with-bg'"
      [class.no-bg]="theme() === 'no-bg'"
      data-testid="comparison-toggle"
      (click)="$event.stopPropagation()"
    >
      <mat-checkbox
        [checked]="checked()"
        (click)="toggle()"
        [disabled]="disabled()"
      >
        {{ compareText }}
      </mat-checkbox>
    </div>
  `,
  styles: [`
    @use "sass:map";
    @import "src/assets/typography";
    .ComparisonToggle {
      @include typography(caption);
      --mdc-checkbox-state-layer-size: 16px;
      --mat-checkbox-label-text-size: 12px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 5px 6px 5px 10px;
      user-select: none;
    }
    .with-bg {
      background: var(--light-black-12p);
      --mat-checkbox-label-text-color: #FFF;
      // selected colors
      --mdc-checkbox-selected-icon-color: #FFF;
      --mdc-checkbox-selected-hover-icon-color: #FFF;
      --mdc-checkbox-selected-focus-icon-color: #FFF;
      --mdc-checkbox-selected-pressed-icon-color: #FFF;
      --mdc-checkbox-selected-checkmark-color: var(--light-black-20p);
      --mdc-checkbox-selected-hover-state-layer-color: var(--light-black-12p);
      --mdc-checkbox-selected-focus-state-layer-color: transparent;
      --mdc-checkbox-selected-pressed-state-layer-color: transparent;
      // unselected colors
      --mdc-checkbox-unselected-icon-color: #FFF;
      --mdc-checkbox-unselected-focus-icon-color: #FFF;
      --mdc-checkbox-unselected-hover-state-layer-color: var(--light-black-12p);
      --mdc-checkbox-unselected-focus-state-layer-color: transparent;
      --mdc-checkbox-unselected-pressed-state-layer-color: transparent;
      --mdc-checkbox-unselected-hover-icon-color: #FFF;
      --mdc-checkbox-unselected-focus-icon-color: #FFF;
      --mdc-checkbox-unselected-pressed-icon-color: #FFF;

      &:hover {
        background: var(--light-black-30p);
      }
    }
    .no-bg {
      background: #FFF;
      --mat-checkbox-label-text-color: var(--light-text-primary);
      // selected colors
      --mdc-checkbox-selected-icon-color: var(--light-text-primary);
      --mdc-checkbox-selected-hover-icon-color: var(--light-text-primary);
      --mdc-checkbox-selected-focus-icon-color: var(--light-text-primary);
      --mdc-checkbox-selected-pressed-icon-color: var(--light-text-primary);
      --mdc-checkbox-selected-checkmark-color: #FFF;
      --mdc-checkbox-selected-hover-state-layer-color: var(--light-text-primary);
      --mdc-checkbox-selected-focus-state-layer-color: transparent;
      --mdc-checkbox-selected-pressed-state-layer-color: transparent;
      // unselected colors
      --mdc-checkbox-unselected-icon-color: var(--light-text-primary);
      --mdc-checkbox-unselected-focus-icon-color: var(--light-text-primary);
      --mdc-checkbox-unselected-hover-state-layer-color: var(--light-text-primary);
      --mdc-checkbox-unselected-focus-state-layer-color: transparent;
      --mdc-checkbox-unselected-pressed-state-layer-color: transparent;
      --mdc-checkbox-unselected-hover-icon-color: var(--light-text-primary);
      --mdc-checkbox-unselected-focus-icon-color: var(--light-text-primary);
      --mdc-checkbox-unselected-pressed-icon-color: var(--light-text-primary);
    }
  `],
  imports: [CommonModule, FormsModule, MatCheckboxModule]
})
export class ComparisonToggleComponent implements OnInit {
  private _comparisonService = inject(ComparisonService)
  compareText = $localize`:@@comparisonToggle_CompareText:Compare`

  checked = signal(false)
  asset = input.required<IAsset>()
  theme = input<'with-bg' | 'no-bg'>('no-bg')

  disabled = computed(() => this._comparisonService.assetsAreFull() && !this._comparisonService.checkAssetBeingCompared(this.asset()))
  isBeingCompared = computed(() => this._comparisonService.assets().some(asset => asset.equipment_number === this.asset()?.equipment_number))

  constructor() {
    toObservable(this._comparisonService.assets).pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.init()
    })
    toObservable(this.asset).pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.init()
    })
  }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.setToggleState()
  }

  setToggleState() {
    if (this._comparisonService.checkAssetBeingCompared(this.asset())) {
      this.checked.set(true)
    } else {
      this.checked.set(false)
    }
  }

  toggle() {
    const newCheckedState = !this.checked()
    this.checked.set(newCheckedState)

    if (newCheckedState) {
      this._comparisonService.addAsset(this.asset())
    } else {
      this._comparisonService.removeAsset(this.asset()?.equipment_number)
    }
  }
}
